const DEV_ENDPOINT = `http://localhost:3000`;
const PROD_ENDPOINT = `https://api.staging.plataformaunidos.net`;
export const NOGQL_ENDPOINT =
  process.env.NODE_ENV === 'development' ? DEV_ENDPOINT : PROD_ENDPOINT;

export const DEV_CHAT_ENDPOINT = `http://localhost:3001`;
export const DEV_CHAT_WS_ENDPOINT = `ws://localhost:3001`;

export const PROD_CHAT_ENDPOINT = `https://chat.staging.plataformaunidos.net`;
export const PROD_CHAT_WS_ENDPOINT = `wss://chat.staging.plataformaunidos.net`;

export const ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? `${DEV_ENDPOINT}/graphql`
    : `${PROD_ENDPOINT}/graphql`;

export const CHAT_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? DEV_CHAT_ENDPOINT
    : PROD_CHAT_ENDPOINT;

export const CHAT_WS_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? DEV_CHAT_WS_ENDPOINT
    : PROD_CHAT_WS_ENDPOINT;

export const DEFAULT_USER_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/plataforma-unidos-bat.appspot.com/o/users%2Fe4c661ce-9c3d-44c6-8c4d-385c602113e8-SIN%20FOTO.jfif?alt=media&token=6b10336f-e822-41cc-9abe-ae993646b424';
